import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div id="loading-wrapper">
            <div id="loading-text">LOADING</div>
            <div id="loading-content"></div>
        </div>
    );
};

export default Loader;
